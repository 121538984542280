<template>
  <iframe :src="dashboardUrl" />
</template>

<script>
import api from '@/services/api'

export default {
  name: 'QueueMonitoring',
  computed: {
    dashboardUrl () {
      return `${api.axios.defaults.baseURL}/monitoring/horizon/dashboard`
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 1161px;
  border: 0;
}
</style>
